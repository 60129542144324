<template>
	<PageLayout :title="$t('registration.enter-sign-up-code')">
		<CForm @submit.prevent="submit">
			<MaterialInput
				id="signupCode"
				v-model="form.value"
				type="text"
				:label="$t('registration.sign-up-code')"
				:error="form.error"
			/>
			<CButton primary :loading="loading" type="submit">
				{{ $t('registration.use-code') }}
			</CButton>
		</CForm>
	</PageLayout>
</template>

<script>
import MaterialInput from '@/components/material-input/MaterialInput';
import PageLayout from '@/components/page-layout/PageLayout';
import CButton from '@/shared/cbutton/CButton';
import CForm from '@/shared/cform/CForm';
import apiClient from '../../api';

export default {
	name: 'InsertStartCode',
	components: {
		MaterialInput,
		PageLayout,
		CButton,
		CForm,
	},
	data() {
		return {
			form: {
				value: '',
				error: null,
			},
		};
	},
	methods: {
		async submit() {
			try {
				this.form.error = null;
				const code = this.form.value;
				const signupCode = await apiClient.checkCode(code);
				this.$router.push({
					path: `/signup`,
					query: { signupCode: signupCode.code },
				});
			} catch (e) {
				this.form.error = e.response.data.error.message;
			}
		},
	},
};
</script>
